import RichEditor from '@/components/RichEditor'
import { Form, Input, Select } from 'antd'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'


type TipsAbilityProps = {
    close: () => void,
    tips?: {
        trigger: number,
        tip: string
    }
}

const TipsAbility = React.forwardRef((props: TipsAbilityProps, ref) => {

    const { tips, close } = props

    const [form] = Form.useForm()

    const [tipsText, setTipsText] = useState<any>()
    const tipsEditor = useRef<any>(null)

    useImperativeHandle(ref, () => ({
        set: () => {
            const tip = tipsEditor?.current?.getHtml()
            form.setFieldsValue({
                tip: tip,
            })
        },
        validateFields: form.validateFields
    }))

    useEffect(() => {
        setTipsText(tips?.tip || '')
        form.setFieldsValue({ ...tips })
    }, [tips])

    return (
        <div className='ylzcc-ability tipability'>
            <div className='ylzcc-activity-header'>
                <span>添加提示</span>
                <span onClick={() => { close?.() }}>删除</span>
            </div>
            <Form
                name="basic"
                colon={false}
                validateTrigger='onBlur'
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    label="触发时机"
                    name={'trigger'}
                    rules={[{ required: true }]}
                >
                    <Select
                        disabled
                        options={[
                            { value: 0, label: '操作结果错误时提示' },
                        ]}
                    />
                </Form.Item>

                <Form.Item
                    label="提示内容"
                    name={'tip'}
                    rules={[
                        { required: true, message: '请输入提示内容', whitespace: true },
                        { min: 1, message: '请至少输入1个字' }
                    ]
                    }
                    validateFirst
                >
                    <RichEditor placeholder='请输入提示内容' ref={tipsEditor} defaultValue={tipsText} />
                </Form.Item>
            </Form>
        </div>
    )
})

export default TipsAbility