import http from "../index"
import * as pako from 'pako'

// 压缩对象
function compress(data: any) {
  try {
    const jsonData = JSON.stringify(data)
    const compressedData: Uint8Array = pako.deflate(new TextEncoder().encode(jsonData));
    const str = Buffer.from(compressedData).toString('base64')
    return {
      data: str
    }
  } catch (error) {
    console.log('数据压缩出错')
    return {
      data: ''
    }
  }
}

// 活动保存       -------
export async function saveActivity(params: any, data: any) {
  return http.post(`/activity/save`, compress(data), {params})
}

// 批量保存活动   -------
export async function batchSaveActivity(params: any, data: any) {
  return http.post(`/activity/batchSave`, compress(data), {params})
}

// 活动列表
export async function queryActivityList(params: any) {
  return http.post(`/activity/list`, {}, {params})
}

// 活动删除
export async function removeActivity(params: any, data: any) {
  return http.post(`/activity/delete`, data, {params})
}

// 保存存有活动    -------
export async function saveLiveActivity(params: any, data: any) {
  return http.post(`/activity/comeSave`, compress(data), {params})
}

// 获取单个活动详情
export async function getActivityInfo(id: string, params: any) {
  return http.get(`/activity/${id}`, params)
}
