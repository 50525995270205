import React from 'react'
import './index.less'

type LineProps = {
    state?: 'row' | 'col',
    move?: Function,
    min?: number,
    minCallback?: Function,    // 达到边界时触发函数
    moveEnd?: Function,
    style?: React.CSSProperties
}

const Line: React.FC<LineProps> = (props) => {

    const { state = 'col', min = 0, move, moveEnd, minCallback, style } = props

    // 选中
    const down = (e: any) => {
        let x = 0
        let y = 0
        let originy = 0

        document.onmousedown = function (v: any) {
            const e = v || window.event;
            x = e.clientX
            y = e.clientY
            originy = e.clientY
            const iframes = document.querySelectorAll('iframe');
            iframes.forEach(iframe => {
                iframe.style.setProperty('pointer-events', 'none')
            })
        };

        if (state === 'row') {
            document.onmousemove = (ev: any) => {
                const moveY = ev.clientY - y
                y = ev.clientY
                move && move(moveY, ev.clientY - originy)
            }
        } else {
            document.onmousemove = (ev: any) => {
                if (ev.clientX > min) {
                    const moveX = ev.clientX - x
                    move && move(moveX)
                } else {
                    minCallback?.()
                }
            }
        }
        document.onmouseup = () => {
            document.onmousedown = null
            document.onmousemove = null
            document.onmouseup = null
            x = 0
            y = 0
            const iframes = document.querySelectorAll('iframe')
            iframes.forEach(iframe => {
                iframe.style.setProperty('pointer-events', 'auto')
            })
            moveEnd && moveEnd()
        }
    }

    return (
        <div style={style} className={state === 'row' ? 'line linerow' : 'line linecol'} onMouseDown={down}></div>
    )
}

export default Line