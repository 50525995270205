import config from "../config"
import { nanoid } from "nanoid"


// 用户行为数据上报


// profill 实现回退 50毫秒的执行时间
window.requestIdleCallback = window.requestIdleCallback || function (handler) {
    let startTime = Date.now()
    return setTimeout(function () {
        handler({
            didTimeout: false,
            timeRemaining: function () {
                return Math.max(0, 50 - (Date.now() - startTime))
            }
        })
    }, 1)
}

// 任务事件id   小节进度   小节交互式活动    实验室进入次数
export type TaskEventId = "chapterProgress" | "chapterActivity" | string | 'labVisitCount'

//#region 
// {
//     "eventId": "chapterProgress",
//     "data" : {
//         "labCode": "实验室编码",
//         "userId": "用户ID",
//         "code": "课程Code",
//         "repo": "课程",
//         "chapter": "小节",
//         "status": 1(1进行中；2已完成),
//         "progress": "进度（文档进度0或100，视频进度按真实上报）"
//     }    
//   }

// {
//     "eventId": "chapterActivity",
//     "data" : {
//         "labCode": "实验室编码",
//         "userId": "用户ID",
//         "code": "课程Code",
//         "repo": "课程",
//         "chapter": "小节",
//         "activityId": "活动id"
//     }
//   }
//#endregion

// 任务类型
export type TaskType = {
    id: string           // 唯一标识
    event: TaskEventId   // 事件名称
    data: object         // 上报的具体数据
    time: number         // 前端触发的时间戳
}

// 任务中心
class Center {

    // 任务中心唯一实例
    private static instance: Center | null = null

    // 任务列表   先进先出
    private taskList: TaskType[] = []
    // 实验室code 课程code 用户id  repo
    private labCode: string = ''
    private code: string = ''
    private userId: string = ''
    private repo: string = ''
    private socket: WebSocket | null = null
    private connectlastTime: number

    private constructor() { }

    // 实例化
    public static from(): Center {
        if (!this.instance) {
            this.instance = new Center()
        }
        return this.instance
    }

    // 判断必要的字段是否已经填充完毕 如果没有无法发送数据
    get hasFiled(): boolean {
        return !!(this.labCode && this.code && this.userId && this.repo)
    }

    // 上报包含的字段
    get field() {
        if (this.hasFiled) {
            return {
                labCode: this.labCode,
                code: this.code,
                userId: this.userId,
                repo: this.repo
            }
        }
        return null
    }

    // 填充必要的字段
    setField(labCode: string, code: string, userId: string, repo: string) {
        this.labCode = labCode
        this.code = code
        this.userId = userId
        this.repo = repo
        this.connect()
        // 可能有数据还没有上报
        this.execTask()
    }

    // 添加一个上报任务
    public addTask(event: TaskType['event'], data: TaskType['data']) {
        const task = {
            id: nanoid(),
            event,
            data,
            time: Date.now()
        }
        this.taskList.push(task)
        this.execTask()
    }

    // 执行上报任务
    private execTask() {
        const handle = (deadline: IdleDeadline) => {
            while ((deadline.timeRemaining() > 0 || deadline.didTimeout) && this.taskList.length && this.hasFiled && this.socket && this.socket.readyState === WebSocket.OPEN) {
                const task = this.taskList.shift()
                // 上报数据
                this.reportedData(task!)
            }
            if (this.taskList?.length) {
                this.execTask()
            }
        }
        // 3秒后强制执行
        requestIdleCallback(handle, { timeout: 3000 })
    }

    // 上报数据  上报失败重新加入到任务列表
    private reportedData(task: TaskType) {
        try {
            const data = {
                eventId: task.event,
                data: {
                    ...this.field,
                    ...task.data
                }
            }
            this.send(JSON.stringify(data))
        } catch (error) { }

        // // console.log('上报的数据', data)
        // reportData(data).then((res) => {
        //     // 上报成功
        //     // console.log('上报成功', task)
        // }).catch((err) => {
        //     // console.log('上报失败', err)
        //     // 上报失败3秒后再重试
        //     // setTimeout(() => {
        //     //     this.taskList.push(task)
        //     //     this.execTask()
        //     // }, 3000);
        // })
    }

    // 数据上报链接生成
    private generateReportDataWss(): string {
        const query = new URLSearchParams()
        query.set('repo', this.repo)
        return `${config.reportWS}/websocket/reportData?${query.toString()}`
    }

    private connect() {
        // const host = config.reportWS
        // const url = `/websocket/reportData`
        this.connectlastTime = new Date().getTime()
        this.socket = new WebSocket(this.generateReportDataWss())
        this.socket.onmessage = this.onmessage.bind(this)
        this.socket.onopen = this.onopen.bind(this)
        this.socket.onclose = this.onclose.bind(this)
        this.socket.onerror = this.onerror.bind(this)
    }

    // 重连
    private reConnect() {
        this.socket = null
        const time = 1000 * 10
        if (this.connectlastTime && (new Date().getTime()) - this.connectlastTime < time) {
            setTimeout(() => {
                this.connect()
            }, time);
        } else {
            this.connect()
        }
    }

    // 连接成功
    private onopen(event: Event) { }

    // 连接关闭
    private onclose(event: CloseEvent) {
        this.socket = null
        if (event.code !== 1005) {
            this.reConnect()
        }
    }

    // 连接错误
    private onerror(event: Event) { }

    // 连接信息接收
    private onmessage(event: MessageEvent<any>) {
        // console.log(event)
    }

    // 断开连接
    close() {
        this.socket?.close()
    }

    // 发送信息
    send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
        this.socket?.send(data)
    }
}

// 用户行为中心处理
export const BehaviorCenter = Center.from()


// websocket
export class SocketSchedule {

    private url: string
    private socket: WebSocket | null = null
    private reconnection: boolean     // 断开或连接失败是否需要重连
    private host: string

    /**
     * 
     * @param labCode 实验室code
     * @param code 课程code
     * @param userId 用户id
     * @param repo repo
     * @param chapter 小节
     * @param eventId 事件标识
     * @param host wss前缀
     * @param url 地址
     * @param reconnection 是否需要重连
     */
    constructor(
        readonly labCode: string,
        readonly code: string,
        readonly userId: string,
        readonly repo: string,
        readonly chapter: string,
        readonly type: string,
        readonly eventId: string = 'chapterTime',
        host: string = config.reportWS!,
        url: string = '/websocket/learningTime',
        reconnection: boolean = true
    ) {
        this.host = host
        this.url = url
        this.reconnection = reconnection
        this.connect()
    }

    // 相关参数字段是否提供
    get hasField(): boolean {
        return !!(this.host && this.url && this.labCode && this.code && this.userId && this.repo && this.chapter && this.eventId && this.type)
    }

    // 链接地址生成
    private generateWss(): string {
        if (this.hasField) {
            const query = new URLSearchParams()
            query.set('eventId', this.eventId)
            query.set('labCode', this.labCode)
            query.set('code', this.code)
            query.set('userId', this.userId)
            query.set('repo', this.repo)
            query.set('chapter', this.chapter)
            query.set('type', this.type)
            return `${this.host}${this.url}?${query.toString()}`
        }
        return ''
    }

    // 连接
    private connect() {
        const wss = this.generateWss()
        // console.log(wss)
        if (wss) {
            this.socket = new WebSocket(wss)
            this.socket.onmessage = this.onmessage.bind(this)
            this.socket.onopen = this.onopen.bind(this)
            this.socket.onclose = this.onclose.bind(this)
            this.socket.onerror = this.onerror.bind(this)
        }
    }

    // 重连
    private reConnect() {
        this.socket = null
        this.connect()
    }

    // 连接成功
    private onopen(event: Event) { }

    // 连接关闭
    private onclose(event: CloseEvent) {
        if (event.code !== 1005 && this.reconnection) {
            this.reConnect()
        }
    }

    // 连接错误
    private onerror(event: Event) { }

    // 连接信息接收
    private onmessage(event: MessageEvent<any>) {
        // console.log(event)
    }

    // 断开连接
    close() {
        this.socket?.close()
    }

    // 发送信息
    send(data: string | ArrayBufferLike | Blob | ArrayBufferView) {
        this.socket?.send(data)
    }
}


